var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c("c-table", {
              ref: "grpGrp",
              attrs: {
                title: "업무그룹",
                tableId: "grpGrp",
                columnSetting: false,
                isFullScreen: false,
                usePaging: false,
                filtering: false,
                columns: _vm.grpGrid.columns,
                isExcelDown: false,
                data: _vm.grpGrid.data,
              },
              on: { rowClick: _vm.getMailReceiver },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c(
              "c-table",
              {
                ref: "receiverTable",
                attrs: {
                  title: "업무별 메일수신자 목록",
                  tableId: "receiverTable",
                  columnSetting: false,
                  isFullScreen: false,
                  usePaging: false,
                  filtering: false,
                  columns: _vm.receiverGrid.columns,
                  isExcelDown: false,
                  data: _vm.receiverGrid.data,
                  selection: "multiple",
                  rowKey: "mailReceiverKey",
                  editable: _vm.editable,
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.isSelectGrp
                          ? _c("c-btn", {
                              attrs: { label: "LBLADD", icon: "add" },
                              on: { btnClicked: _vm.addrow },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.isSelectGrp
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "remove" },
                              on: { btnClicked: _vm.removeRow },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "template",
                  { slot: "table-chip" },
                  [
                    _vm.isSelectGrp
                      ? _c(
                          "q-chip",
                          {
                            attrs: {
                              outline: "",
                              square: "",
                              icon: "push_pin",
                              color: "blue",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.selectedGroupName) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }