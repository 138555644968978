<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="grpGrp"
          title="업무그룹"
          tableId="grpGrp"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="grpGrid.columns"
          :isExcelDown="false"
          :data="grpGrid.data"
          @rowClick="getMailReceiver"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="receiverTable"
          title="업무별 메일수신자 목록"
          tableId="receiverTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="receiverGrid.columns"
          :isExcelDown="false"
          :data="receiverGrid.data"
          selection="multiple"
          rowKey="mailReceiverKey"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addrow" v-if="editable && isSelectGrp" />
              <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeRow" v-if="editable && isSelectGrp" />
            </q-btn-group>
          </template>
          <template slot="table-chip">
            <q-chip outline square icon="push_pin" color="blue" v-if="isSelectGrp">
              {{ selectedGroupName }}
            </q-chip>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'main-receiver-manage',
  data() {
    return {
      editable: true,
      isSelectGrp: false,
      selectedGroupCd: '',
      selectedGroupName: '',
      grpGrid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '코드',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'codeName',
            field: 'codeName',
            label: '업무그룹명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      receiverGrid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서명',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '사용자 명',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      insertUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.mail.receiver.list.url;
      this.insertUrl = transactionConfig.sys.mail.receiver.insert.url;
      this.deleteUrl = transactionConfig.sys.mail.receiver.delete.url;
      
      this.$comm.getComboItems('MAIL_RECEIVER').then(__result1 => {
        this.grpGrid.data = __result1;
      });
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getMailReceiver(row) {
      this.isSelectGrp = true;
      this.selectedGroupCd = row.code;
      this.selectedGroupName = row.codeName;
      this.$http.url = this.listUrl;
      this.$http.param = {mailReceiver : row.code}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.receiverGrid.data = _result.data;
      },
      () => {
      });
    },
    addrow() {
      this.popupOptions.title = 'LBL0000318'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.receiverGrid.data, { userId: item.userId }) === -1) {
            saveData.push({
              mailReceiver: this.selectedGroupCd,  // 사업장코드
              userId: item.userId,  // 안전관리자 아이디
              editFlag: 'C',
            })
          }
        });
        this.$http.url = this.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getMailReceiver({code: this.selectedGroupCd, codeName: this.selectedGroupName});
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['receiverTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getMailReceiver({code: this.selectedGroupCd, codeName: this.selectedGroupName});
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
